<template lang="pug">
.favorite-asset-list
  template(v-if="loading")
    .favorite-asset-list__toolbar.px-4
      ui-skeleton.text-small(type="text" width=70 height=20)
    .favorite-asset-list__items.pa-4
      ui-skeleton.mb-4(type="asset-card" width="100%" height=430)
      ui-skeleton(type="asset-card" width="100%" height=430)

  template(v-if="!loading && assets.length")
    .favorite-asset-list__toolbar.t-button-small.c-text.d-flex.justify-space-between.align-center.px-4
      span {{ totalAssetCount | assetCount }}
      //- span Сортировка
    .favorite-asset-list__items.pa-4
      asset-card.mb-4(v-for="asset in assets" :key="asset.short_id" :asset="asset")

      .mt-10.mb-8
        ui-btn.mb-4(
          v-if="totalAssetCount > assets.length"
          min-height=40
          preset="rounded"
          width="100%"
          @click="fetchMoreFavorites"
        )
          ui-icon(name="renew" left)
          | Показать ещё
        .t-caption-normal.text-center.c-black60 Показано {{ assets.length }} из {{ totalAssetCount }}

  .favorites__list--empty(v-if="!loading && !assets.length")
    titled-image(small title="У вас нет избранных активов")
      template(#image)
        img(src="~@/assets/96/favorites.svg")
      | Добавьте активы в избранное, чтобы увидеть их в этом разделе
    router-link.favorites__link.mt-2(:to="$path.catalog()")
      ui-btn(primary) Перейти в каталог
</template>

<script>
  import { UiLoader, UiBtn, UiIcon, UiSkeleton } from '@/uikit';
  import AssetCard from '@/components/asset/AssetCard.vue';
  import TitledImage from '@/components/TitledImage.vue';

  import { service as API } from '@/services/assets';
  import { handleError } from '@/libs/handle-error';
  import { createLogger } from '@/uikit/util';
  const logger = createLogger('FavoriteAssetsList', 'orange');

  export default {
    components: {
      AssetCard,
      TitledImage,
      UiLoader,
      UiBtn,
      UiIcon,
      UiSkeleton
    },

    data() {
      return {
        loading: true,
        assets: [],
        totalAssetCount: undefined,
        currentPage: 1,
        perPage: 5,
      };
    },

    mounted() {
      this.fetchFavorites();
    },

    methods: {
      async fetchFavorites() {
        try {
          this.currentPage = 1;
          this.loading = true;
          const { assets, total } = await API.getFavorites(this.currentPage);
          this.assets = assets;
          this.totalAssetCount = total;
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.loading = false;
        }
      },

      async fetchMoreFavorites() {
        try {
          this.currentPage++;
          this.loading = true;
          const { assets, total } = await API.getFavorites(this.currentPage);
          this.assets = [...this.assets, ...assets];
          this.totalAssetCount = total;
        } catch (error) {
          logger.error(error);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>

<style lang="scss">
  .favorite-asset-list {
    .favorites__list {
      width: 100%;
      line-height: 18px;
    }

    .favorites__list--empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 480px;
      padding: 32px;
    }

    .favorites__link {
      text-decoration: none;
    }
  }
</style>
