<template lang="pug">
.saved-presets-list
  template(v-if="loading")
    .saved-presets-list__items.pa-4
      ui-skeleton.mb-4(width="100%" height=150)
      ui-skeleton.mb-4(width="100%" height=150)
      ui-skeleton(width="100%" height=150)

  template(v-if="!loading && presets.length")
    .saved-presets-list__items.pa-4
      search-preset-card.mb-4(
        v-for="preset in presets"
        :key="preset.uuid"
        :preset="preset"
        @click="openCatalog(preset)"
        @click:delete="deletePreset(preset)"
      )

      .mt-10.mb-8
        ui-btn.mb-4(
          v-if="totalPresetCount > presets.length"
          min-height=40
          preset="rounded"
          width="100%"
          @click="fetchMorePresets"
        )
          ui-icon(name="renew" left)
          | Показать ещё
        .t-caption-normal.text-center.c-black60 Показано {{ presets.length }} из {{ totalPresetCount }}

  .saved-presets-list__empty(v-if="!loading && !presets.length")
    titled-image(small title="У вас нет сохраненных поисков")
      template(#image)
        img(src="~@/assets/save-search.svg")
      | Нажмите кнопку «Сохранить поиск» в каталоге и подпишитесь на новые активы
    router-link.saved-presets-list__link.mt-2(:to="$path.catalog()")
      ui-btn(primary) Перейти в каталог
</template>

<script>
  import { UiBtn, UiIcon, UiLoader, UiSkeleton } from '@/uikit';
  import SearchPresetCard from '@/components/favorites/SearchPresetCard.vue';
  import TitledImage from '@/components/TitledImage.vue';

  import { service as API } from '@/services/assets';
  import { handleError } from '@/libs/handle-error';

  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('SearchPresetsList', 'orange');

  export default {
    components: {
      UiBtn,
      UiIcon,
      UiLoader,
      UiSkeleton,
      SearchPresetCard,
      TitledImage,
    },

    data() {
      return {
        loading: true,
        presets: [],
        totalPresetCount: undefined,
        currentPage: 1,
        perPage: 5,
      };
    },

    mounted() {
      this.fetchPresets();
    },

    methods: {
      async fetchPresets() {
        try {
          this.currentPage = 1;
          this.loading = true;
          const { presets, total } = await API.getSearchPresets(this.currentPage);
          this.presets = presets;
          this.totalPresetCount = total;
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.loading = false;
        }
      },

      async fetchMorePresets() {
        try {
          this.currentPage++;
          this.loading = true;
          const { presets, total } = await API.getSearchPresets(this.currentPage);
          this.presets = [...this.presets, ...presets];
          this.totalPresetCount = total;
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.loading = false;
        }
      },

      async deletePreset(preset) {
        try {
          const ok = await API.deleteSearchPreset(preset.uuid);
          if (ok) {
            this.presets = this.presets.filter((p) => p.uuid !== preset.uuid);
            this.totalPresetCount--;
            this.$notify.success('Поиск удален из избранного');
          }
        } catch (error) {
          handleError(error, logger);
        }
      },

      async openCatalog(preset) {
        const params = preset.search_params;
        const purpose = params.placement_purpose;
        const kind = params.search?.kind;

        this.$router.push({
          name: 'pageCatalog',
          params: { assetPurpose: purpose, assetKind: kind },
          query: { search_id: preset.uuid },
        });
      },
    },
  };
</script>

<style lang="scss">
  .saved-presets-list {
    &__empty {
      width: 100%;
      line-height: 18px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 480px;
      padding: 32px;
    }

    &__link {
      text-decoration: none;
    }
  }
</style>
