<template lang="pug">
v-sheet.search-preset-card(v-if="preset" v-on="$listeners" v-bind="$attrs")
  .t-normal-bold.c-black.mb-2 {{ preset.name }}
  .t-caption-small.c-black80.mb-4 {{ preset.description }}

  .d-flex.align-center
    .t-caption-small.c-black80 {{ preset.assets_total | assetCount }}
    ui-badge.ml-2(
      v-if="preset.assets_recent > 0"
      color="green"
      inline
      rounded
    ) +{{ preset.assets_recent }}
    v-spacer
    ui-btn.px-0(
      plain
      width=32
      height=32
      @click.stop="$emit('click:delete')"
    )
      ui-icon(name="trash")
  .t-small.c-text.mt-2(v-if="preset.checked_at") Обновлено: {{ preset.checked_at | datetime }}
</template>

<script>
  import { UiIcon, UiBtn, UiBadge } from '@/uikit/';
  export default {
    components: { UiIcon, UiBtn, UiBadge },
    inheritAttrs: false,
    props: { preset: { type: Object, default: () => undefined } },
  };
</script>

<style lang="scss">
  .search-preset-card {
    @include box-shadow('xsmall', 'light');
    padding: 20px 16px 16px 16px;
    border-radius: 4px !important;
  }
</style>
