<template lang="pug">
app-layout
  .page-favorites
    v-overlay(
      :value="loading"
      color="white"
      absolute
      opacity="1"
    )
      ui-loader(text="Загрузка...")

    h2.t-h1-mobile.my-6.mx-4 Избранное

    v-tabs.page-favorites__tabs.mb-4(v-model="tab")
      v-tab.ml-4 Активы
      v-tab Поиски

    favorite-assets-list(v-if="tab === 0" @update:loading="loading = $event")
    search-presets-list(v-if="tab === 1" @update:loading="loading = $event")
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import FavoriteAssetsList from '@/components/favorites/FavoriteAssetsList.vue';
  import SearchPresetsList from '@/components/favorites/SearchPresetsList.vue';

  import { UiLoader, UiBtn, UiIcon } from '@/uikit';

  import { createLogger } from '@/uikit/util';
  const logger = createLogger('PageFavorites', 'orange');

  export default {
    name: 'page-favorites',
    components: {
      AppLayout,
      UiLoader,
      UiBtn,
      UiIcon,
      FavoriteAssetsList,
      SearchPresetsList,
    },

    data() {
      return {
        loading: false,
        tab: 0,
      };
    },
  };
</script>

<style lang="scss">
  .page-favorites {
    height: 100%;
    min-height: 480px;
  }

  .page-favorites__tabs {
    color: c('primary', 'light');
  }
</style>
